.fever-cookie__container 
    background: #E6ECF8

.fever-cookie__bar-text
    color: #000

.fever-cookie__bar .fever-button
    background: #004A93!important
    border-color: #004A93!important

.fever-cookie__bar .fever-button.fever-button--settings
    background: none!important
    color: #004A93!important

.fever-cookie__modal-container, .fever-cookie__modal-header, .fever-cookie__modal-footer
    background: #004A93!important

.fever-cookie__modal-button:not(.fever-cookie__modal-button:first-of-type), .fever-cookie__modal-button-close
    color: #004A93!important