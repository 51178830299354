.cart-card
    margin-bottom: 24px

    figure
        display: grid
        grid-template-columns: minmax(97px, 1fr) 3fr
        grid-gap: 16px
        align-items: center

        picture
            border-radius: 7px
            overflow: hidden

            img
                width: 100%
                height: 100%
                object-fit: cover

        

    .caption-header
        display: flex
        align-items: center
        justify-content: space-between
    
    .product-name
        font-size: 12px
        line-height: 18px
        font-weight: 500
        font-family: $fontInter

    .product-brand
        font-size: 10px
        line-height: 24px
        font-weight: 900
        text-transform: uppercase
        margin-bottom: 2px

    .caption-footer
        display: flex
        justify-content: space-between
        align-items: center 

    .new-price
        color: $color-red
        font-size: 14px
        line-height: 22px
        font-weight: 900
        margin-right: 11px
    
    .old-price
        font-size: 10px
        line-height: 24px
        font-weight: 900
        margin-right: 11px
        position: relative

        // &::before
        //     content: ''
        //     position: absolute
        //     top: 50%
        //     left: -5px
        //     right: -5px
        //     height: 1px
        //     background: $color-black
        //     transform: translateY(-50%) rotate(5deg)
        