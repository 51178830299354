.quantity-wrapper 
    display: flex
    padding: 8px
    align-items: center
    border: 1px solid $color-grey-2
    border-radius: 28px

    .minus-button, .plus-button
        width: 21px
        height: 21px
        position: relative

    .minus-button

        background: $color-grey-3
        border-radius: 50%

        &[disabled] 
            background: none

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 9px
            height: 1px
            background: $color-blue

    .plus-button

        background: $color-blue
        border-radius: 50%

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 9px
            height: 1px
            background: $color-white
        
        &::before
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 1px
            height: 9px
            background: $color-white

        
    .product-quantity
        margin: 0 8px
        font-size: 14px
        font-family: $fontInter