.search-wrapper
    padding: 18px 16px

    header
        position: relative
    
    .back-button
        position: absolute
        top: 5px
        left: 5px

    .close-button
        position: absolute
        top: 5px
        right: 5px

    input
        background: $color-grey-3
        width: 100%
        border-radius: 36px
        border: 0
        outline: 0
        padding: 8px 47px
        box-sizing: border-box
        color: $color-black
        font-size: 18px
        line-height: 24px
        font-weight: 500
        font-family: $fontInter
        margin-bottom: 16px

        &::placeholder
            color: $color-grey-4

    .title-5
        margin-bottom: 32px

    .wrapper-not-found
        position: fixed
        top: 50%
        left: 50%
        padding: 40px
        width: calc(100% - 80px)
        text-align: center
        transform: translate(-50%,-50%)