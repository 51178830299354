.category-card
    display: flex
    position: relative
    padding: 16px 21px
    margin-bottom: 16px
    background: #FFFFFF
    box-shadow: -1px 1px 40px rgba(0, 0, 0, 0.07)
    border-radius: 8px
    align-items: center

    @supports(-webkit-appearance: none) or (-moz-appearance: none) 
        input[type='checkbox']
            -webkit-appearance: none
            -moz-appearance: none
            width: 26px
            min-width: 26px
            height: 26px
            border: 1.5px solid $color-black
            border-radius: 50%

            &:checked
                border-color: $color-grey-4
                
                + label 
                    color: $color-grey-4
                    text-decoration: line-through

                    .icon-check
                        opacity: 1

    label 
        position: relative
        margin-left: 9px
        width: calc(100% - 70px)

    .icon-check 
        position: absolute
        top: 50%
        left: -30px
        width: 12.5px
        height: 7.5px
        opacity: 0
        transform: translateY(-50%)
        transition: opacity 250ms ease-in-out

    .remove-item
        position: absolute
        top: 50%
        right: 21px
        transform: translateY(-50%)