.search-card
    background: $color-white
    border-radius: 8px
    box-shadow: -1px 1px 40px rgba($color-black,.07)
    padding: 16px 16px
    margin-bottom: 16px
    display: block
    position: relative

    .price-wrapper
        align-self: flex-end
    
    .new-price
        margin-right: 12px
        color: $color-red

        .big-price 
            font-size: 20px

    .old-price
        // text-decoration: line-through

    .footnote-2
        margin-bottom: 12px

    .label
        position: absolute
        top: 9px
        left: 0
        max-width: 85px
        background: $color-yellow
        padding: 5px 7px 3px
        border-top-right-radius: 8px
        border-bottom-right-radius: 8px

    .pvpr
        display: block
        line-height: 1.2
        font-weight: 600
        font-size: 62.5%

    figure
        display: grid
        align-items: center
        width: 100%
        grid-template-columns: 94px 1fr
        grid-gap: 15px

    
    .bottom-wrapper 
        display: flex
        align-content: flex-end
        justify-content: space-between

        width: 100%
    
    .add-button
        width: 32px
        height: 32px
        border-radius: 50%
        background: $color-blue
        display: block
        position: relative

        &::after
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            height: 1px
            width: 13px
            background: $color-white

        &::before
            content: ''
            position: absolute
            top: 50%
            left: 50%
            transform: translate(-50%,-50%)
            width: 1px
            height: 13px
            background: $color-white
