p 
    font-size: 16px 
    line-height: 24px
    font-weight: 300
    font-family: $fontInter

.large-title
    font-size: 50px
    line-height: 58px
    font-weight: 900
    font-family: $fontNunito

.large-title-2
    font-size: 24px
    line-height: 32px
    font-weight: 500
    font-family: $fontInter
              

.title-1
    font-size: 40px
    line-height: 48px
    font-weight: 900
    font-family: $fontNunito

.title-2
    font-size: 35px
    line-height: 42px
    font-weight: 900
    font-family: $fontNunito

.title-3
    font-size: 30px
    line-height: 40px
    font-weight: 900
    font-family: $fontNunito

.title-4
    font-size: 24px
    line-height: 32px
    font-weight: 900
    font-family: $fontNunito

.title-5
    font-size: 20px
    line-height: 28px
    font-weight: 500
    font-family: $fontInter

.title-6
    font-size: 18px
    line-height: 24px
    font-weight: 500
    font-family: $fontInter

.headline-1
    font-size: 20px
    line-height: 28px
    font-weight: 900
    font-family: $fontNunito

.headline-2
    font-size: 18px
    line-height: 26px
    font-weight: 900
    font-family: $fontNunito

.body-1
    font-size: 16px
    line-height: 18px
    font-weight: 900
    font-family: $fontNunito

.body-2
    font-size: 14px
    line-height: 22px
    font-weight: 900
    font-family: $fontNunito

.body-3
    font-size: 16px
    line-height: 24px
    font-weight: 300
    font-family: $fontInter

.body-4
    font-size: 16px
    line-height: 24px
    font-weight: 700
    font-family: $fontInter

.body-5
    font-size: 14px
    line-height: 18px
    font-weight: 500
    font-family: $fontInter

.footnote-1
    font-size: 12px
    line-height: 24px
    font-weight: 900
    font-family: $fontNunito

.footnote-2
    font-size: 10px
    line-height: 12px
    font-weight: 900
    font-family: $fontNunito

.footnote-3
    font-size: 8px
    line-height: 24px
    font-weight: 700
    font-family: $fontNunito

.footnote-4
    font-size: 12px
    line-height: 18px
    font-weight: 500
    font-family: $fontInter

.footnote-5
    font-size: 10px
    line-height: 16px
    font-weight: 600
    font-family: $fontInter