.cover
    position: relative

    figure
        padding-top: min(400px, 100vw)
        picture
            position: fixed
            top: 0
            width: 100vw
            pointer-events: none
            max-width: 600px
            img
                object-fit: cover
                width: 100%
                max-height: 400px

        figcaption
            background: $color-white
            padding: 32px 41px
            border-top-left-radius: 30px
            border-top-right-radius: 30px
            transform: translateY(-35px)
        p 
            margin-bottom: 12px
        .title-2
            color: $color-blue

    

.cover-slider

    figure img
        max-height: 375px
        object-fit: cover
        width: 100%
    figcaption
        margin: 0 30px
        border-radius: 8px
        box-shadow: -1px 1px 40px rgba($color-black,.07)
        padding: 32px
        background: $color-white
        transform: translateY(-50px)

.link-wrapper
    margin-top: 20px
    color: $color-blue
    font-size: 12px
    display: flex
    justify-content: space-between
    font-weight: 700
