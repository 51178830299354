@font-face 
    font-family: Nunito
    font-weight: 400
    src: url('./../../fonts/Nunito-Regular.woff') format('woff')

@font-face 
    font-family: Nunito
    font-weight: 700
    src: url('./../../fonts/Nunito-Bold.woff') format('woff')

@font-face 
    font-family: Nunito
    font-weight: 900
    src: url('./../../fonts/Nunito-Black.woff') format('woff')

@font-face 
    font-family: Inter
    font-weight: 300
    src: url('./../../fonts/Inter-Light.woff') format('woff')

@font-face 
    font-family: Inter
    font-weight: 400
    src: url('./../../fonts/Inter-Regular.woff') format('woff')

@font-face 
    font-family: Inter
    font-weight: 500
    src: url('./../../fonts/Inter-Medium.woff') format('woff')

@font-face 
    font-family: Inter
    font-weight: 600
    src: url('./../../fonts/Inter-SemiBold.woff') format('woff')

@font-face 
    font-family: Inter
    font-weight: 700
    src: url('./../../fonts/Inter-Bold.woff') format('woff')

$fontInter: Inter,sans
$fontNunito: Nunito,sans