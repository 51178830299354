.product-list-wrapper
    padding: 48px 16px
    scroll-behavior: auto
    
    header
        margin-bottom: 32px
        width: 100%
        display: flex
        align-items: center
        

        .back-button
            background: $color-grey-3
            border-radius: 50%
            margin-right: 16px

            img
                width: 32px
                height: 32px

    .search-card .label
        top: 17px
        left: 17px
        font-size: 12px
        border-radius: 6px
        padding: 2px
        line-height: 16px


    .content-wrapper
        .content
            display: none

            &.is-active
                display: block

            figcaption
                ul
                    padding-left: 15px
                    list-style: disc

                    li
                        list-style: disc

                strong
                    font-weight: 600
                
                ol
                    padding-left: 15px
                    list-style: numeric

        figure:not(.search-card figure)

            picture
                border-radius: 18px
                overflow: hidden
                display: block
                margin-bottom: 32px

                img 
                    width: 100%


.add-all-button 
    width: calc(100% - 48px)
    background: $color-blue
    color: $color-white
    border-radius: 40px
    padding: 18px 56px
    margin: 0 24px
    text-transform: uppercase
    font-size: 16px
    font-family: $fontInter
    font-weight: 700