.loader 
    position: fixed
    top: 0
    left: 0
    opacity: 0
    z-index: 100000
    pointer-events: none
    width: 100vw
    height: 100vh
    background: $color-white
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    transition: .7s all

    &.loading 
        opacity: 1
        pointer-events: auto

    img
        display: block
        margin: 0 auto 38px

    .loader-bar 
        position: relative
        width: 140px
        height: 5px
        background-color: #C4C4C4

    .loader-bar-color 
        position: absolute
        top: 0
        right: 0
        bottom: 0
        left: 0
        background-color: $color-blue
        animation: loader infinite 3s
        
        
@keyframes loader 
    0% 
        width: 0

    50%,
    100% 
        width: 100%

