.catalog-card
    position: relative
    background: $color-grey-3
    border-radius: 24px

    .partner-logo
        position: absolute
        padding: 5px 10px
        background: $color-white
        box-shadow: 0px 12px 158px rgba(0, 0, 0, 0.11), 0px 3.92223px 57.6726px rgba(0, 0, 0, 0.075898), 0px 1.93598px 27.999px rgba(0, 0, 0, 0.061192), 0px 0.971662px 13.7256px rgba(0, 0, 0, 0.048808), 0px 0.396426px 5.42713px rgba(0, 0, 0, 0.034102)
        object-fit: cover
        z-index: 2

    .seal-logo
        position: absolute
        right: 24px
        z-index: 2

    .product-img
        position: absolute
        left: 50%
        width: 100%
        transform: translateX(-50%)
        object-fit: contain
        z-index: 1

    .card-info-icon-wrapper 
        position: absolute
        display: flex
        bottom: 16px
        width: 100%
        align-items: flex-end
        justify-content: space-between
        z-index: 2

    &.duration-card
        .card-info-icon-wrapper 
            bottom: 48px

        .see-more-button
            bottom: 50px

        .tag-wrapper
            &.tag-wrapper-2
                &:not(.layout-small-cards)
                    bottom: 48px

            &.tag-wrapper-4
                bottom: 48px

    .card-info
        max-width: calc(100% - 58px)

        .title-6 
            margin-top: 8px
            opacity: .5

        > .footnote-4
            display: -webkit-box
            -webkit-box-orient: vertical
            -webkit-line-clamp: 4
            overflow: hidden
            overflow-wrap: break-word
            hyphens: auto

    .see-more-button
        box-shadow: 0px 12px 158px rgba(0, 0, 0, 0.11), 0px 3.92223px 57.6726px rgba(0, 0, 0, 0.075898), 0px 1.93598px 27.999px rgba(0, 0, 0, 0.061192), 0px 0.971662px 13.7256px rgba(0, 0, 0, 0.048808), 0px 0.396426px 5.42713px rgba(0, 0, 0, 0.034102)
        display: flex
        border-radius: 50%
        padding: 8px
        background: $color-white
        right: 12px
        bottom: 14px
        width: 24px
        height: auto

        &:not(.card-info-icon-wrapper .see-more-button)
            position: absolute
            right: 24px
            z-index: 3

    .duration
        padding: 6px 12px 4px
        position: absolute
        bottom: 0
        left: 50%
        transform: translateX(-50%)
        font-size: 12px
        font-weight: 700
        line-height: 16px
        background: $color-white
        border-radius: 8px 8px 0 0
        display: flex
        width: auto
        align-items: center
        color: $color-blue
        text-transform: uppercase
        z-index: 3

        img 
            margin-right: 6px

        span
            white-space: nowrap 

    .right-corner
        position: absolute
        bottom: -2px
        transform: rotate(270deg)    
        left: calc(100% - 2px)
        z-index: -1
        height: 20px  


    .left-corner
        position: absolute
        bottom: -2px
        transform: rotate(180deg)    
        right: calc(100% - 7px)
        z-index: -1
        height: 20px  
            
    .tag-wrapper
        position: absolute
        display: inline-block
        z-index: 4

        &:not(.tag-wrapper-1, .tag-wrapper-4)
            .big-tag
                margin-left: 10px

        &.tag-wrapper-1
            position: initial

            .bottom-tag 
                position: absolute
                bottom: 48px
                padding: 0
                max-width: 115px
                background: transparent
                z-index: 2

            .sale-label
                margin-bottom: -35px
                padding: 8px 10px 40px 10px
                width: fit-content
                background: $color-red
                border-radius: 12px
                color: $color-white
                font-family: $fontNunito
                font-weight: 900
                font-size: 30px 
                line-height: 38px

            .card-name
                padding: 10px 16px
                margin-left: 10px
                background: $color-red
                color: $color-white
                border-radius: 12px
                font-family: $fontInter
                font-weight: 500
                font-size: 14px
                line-height: 18px

        &.tag-wrapper-2
            min-width: 120px
            &:not(.layout-small-cards)
                position: absolute
                bottom: 16px
                left: 24px

            .big-tag 
                background: $color-red
                color: $color-white

                .layout-big-cards &
                    color: $color-white

            .small-tag
                background: $color-yellow
                color: $color-black

            .card-name 
                text-align: left
                min-width: 80px

        &.tag-wrapper-3, &.tag-wrapper-5
            .small-tag
                text-transform: uppercase

        &.tag-wrapper-4
            bottom: 16px

            .big-tag
                padding: 0 !important
                background: transparent
                color: $color-black

            .upper-label
                margin-bottom: -30px
                padding: 8px 10px 40px 10px
                width: fit-content
                background: $color-yellow
                color: $color-black
                border-radius: 12px
                text-transform: uppercase

            .card-name 
                padding: 10px 16px
                margin-left: 10px
                background: $color-red
                border-radius: 12px
                color: $color-white
                z-index: 2
                text-align: left

        .old-price
            display: block
            margin-bottom: -5px
            text-align: right
            // text-decoration: line-through

        .small-tag 
            margin-bottom: -26px
            padding: 8px 8px 35px 8px
            width: fit-content
            background: $color-yellow
            color: $color-black
            border-radius: 12px

        .big-tag
            padding: 8px 8px 8px 10px
            width: fit-content
            background: $color-red
            border-radius: 16px
            color: $color-white
            text-align: right

    .upper-content, .bottom-content
        display: block

    .bottom-content
        margin-top: -5px

    .quantity-info
        span 
            position: relative
        
        span:first-child
            padding-right: 20px

            &:after
                content: '•'
                position: absolute
                top: 0
                right: 10px
                width: 4px
                height: 4px
                color: $color-black

    .pvpr
        display: block
        line-height: 1.2
        font-weight: 600
        font-size: 62.5%

    

    &.content-card
        overflow: hidden
        grid-column: span 2 !important

        &::before
            content: ''
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: 0
            width: 100%
            height: 100%
            background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%)
            mix-blend-mode: normal
            opacity: 0.3
            transform: rotate(-180deg)
            z-index: 1

        .product-img
            position: absolute
            top: 0 !important
            right: 0 !important
            bottom: 0 !important
            left: 0 !important
            width: 100% !important
            height: 100% !important
            max-height: 100% !important
            object-fit: cover !important
            transform: none !important
            z-index: 0 !important

        .video-duration, .card-info-icon-wrapper
            position: absolute
            color: $color-white
            z-index: 1

        .video-duration
            left: 50%
            transform: translateX(-50%)
            font-feature-settings: "tnum"

        .card-info-icon-wrapper
            bottom: 24px
            width: calc(100% - 48px)

        .card-info
            .footnote-4
                display: -webkit-box
                -webkit-box-orient: vertical
                -webkit-line-clamp: 4
                font-size: 16px !important
                line-height: 24px !important
                overflow: hidden

            .footnote-5
                text-transform: uppercase

    &.small-card
        grid-column: span 1
        padding: 14px 12px 48px 16px
        height: calc(337px - (14px + 48px))

        .partner-logo
            left: 16px
            width: 70px
            height: 47px
            border-radius: 9px

        .product-img
            top: 87px
            max-height: 126px

        .card-info-icon-wrapper 
            right: 12px
            left: 16px
            width: calc(100% - (12px + 16px))

        .see-more-button:not(.card-info-icon-wrapper .see-more-button)
            right: 12px

        &.duration-card 
            .tag-wrapper
                &.tag-wrapper-2
                    .big-tag
                        bottom: 48px

                &.tag-wrapper-4:not(.layout-big-cards &)
                    .card-name
                        bottom: 48px

        .tag-wrapper 
            max-width: 92px

            &.tag-wrapper-2
                position: initial

                .top-tag
                    padding: 8px 9px
                    background: $color-yellow
                    color: $color-black
                    
                    .title-4
                        font-size: 16px
                        line-height: 28px

                .big-tag
                    position: absolute
                    right: 12px
                    left: 16px
                    bottom: 16px
                    padding: 0
                    margin-left: 0
                    width: calc(100% - (12px + 16px))
                    background: transparent
                    color: $color-black
                    z-index: 2

            &.tag-wrapper-3, &.tag-wrapper-5
                .small-tag
                    font-size: 10px
                    line-height: 12px

            &.tag-wrapper-4:not(.layout-big-cards &)
                position: initial
                   
                .upper-label
                    margin-bottom: 0
                    padding: 8px 10px
                    font-size: 10px
                    line-height: 12px

                .card-name 
                    display: -webkit-box
                    -webkit-box-orient: vertical
                    -webkit-line-clamp: 4
                    position: absolute
                    bottom: 16px
                    padding: 0
                    margin-left: 0
                    border-radius: unset
                    background: transparent
                    color: $color-black
                    overflow: hidden

            .card-name
                max-width: 81px
                font-family: $fontInter
                font-size: 12px
                font-weight: 500
                line-height: 16px

        .big-price:not(.old-price .big-price)
            font-size: 30px
            line-height: 38px

        .small-price:not(.old-price .small-price)
            font-size: 24px
            line-height: 30px

    &.medium-card
        grid-column: span 2
        padding: 27px 24px 48px 24px
        height: calc(381px - (27px + 48px))

        .partner-logo
            left: 24px
            width: 75px
            height: 45px
            border-radius: 12px

        .product-img
            top: 32px
            max-height: 217px

        .card-info-icon-wrapper 
            right: 24px
            left: 24px
            width: calc(100% - (24px + 24px))

        .tag-wrapper 
            max-width: 130px

            .big-tag
                padding: 5px 12px

            &.tag-wrapper-3, &.tag-wrapper-5
                .small-tag
                    font-size: 12px
                    line-height: 16px

            &.tag-wrapper-4
                position: absolute

                .card-name
                    position: initial
                    padding: 10px 16px
                    margin-left: 10px
                    background: $color-red
                    color: $color-white
                
                .upper-label
                    margin-bottom: -30px
                    padding: 8px 10px 40px 10px
                    font-size: 16px                       
                    line-height: 20px

        .big-price:not(.old-price .big-price)
            font-size: 35px
            line-height: 42px

        .small-price:not(.old-price .small-price)
            font-size: 25px
            line-height: 32px

        .card-info
            > .footnote-4
                font-size: 18px
                line-height: 24px            

    &.big-card
        grid-column: span 2
        padding: 24px 24px 48px 24px
        height: calc(596px - (24px + 48px))

        .partner-logo
            left: 24px
            width: 122px
            height: 82px
            border-radius: 16px

        .product-img
            top: 135px
            height: 266px

        .card-info-icon-wrapper 
            right: 24px
            left: 24px
            width: calc(100% - (24px + 24px))
        
        .tag-wrapper 
            max-width: 140px

            &.tag-wrapper-3, &.tag-wrapper-5
                .small-tag
                    font-size: 16px
                    line-height: 20px

            .small-tag
                margin-bottom: -36px
                padding: 8px 10px 40px 10px

        .big-price:not(.old-price .big-price)
            font-size: 50px
            line-height: 58px

        .small-price:not(.old-price .small-price)
            font-size: 35px
            line-height: 42px

        .card-info
            > .footnote-4
                font-size: 24px
                line-height: 30px

// video Modal

.video-modal
    position: fixed
    top: 0
    bottom: 0
    left: 50%
    right: 0
    z-index: 100
    transform: translateX(-50%)
    max-width: 600px
    width: 100%

    video
        width: 100%
        height: 100%
        object-fit: cover
    
    .modal-back
        position: absolute
        top: 20px
        left: 20px
        width: 32px
        background: $color-grey-2
        border-radius: 50%
        height: 32px
    .video-info
        position: absolute
        bottom: 50px
        left: 26px
        display: flex
        justify-content: space-between
        width: calc(100% - 52px)
        align-items: center
        color: $color-white

        img     
            box-shadow: 0px 12px 158px rgb(0 0 0 / 11%), 0px 3.92223px 57.6726px rgb(0 0 0 / 8%), 0px 1.93598px 27.999px rgb(0 0 0 / 6%), 0px 0.971662px 13.7256px rgb(0 0 0 / 5%), 0px 0.396426px 5.42713px rgb(0 0 0 / 3%)
            border-radius: 50%
            padding: 8px
            background: #FFFFFF

        .footnote-5
            text-transform: uppercase

        


// SMALL Layout
.layout-small-cards 
    .catalog-card            
        &.medium-card, &.big-card, &.small-card
            grid-column: span 1
            padding: 14px 12px 48px 16px
            height: calc(337px - (14px + 48px))

            .product-img
                top: 87px
                max-height: 126px

            .card-info-icon-wrapper 
                right: 12px
                left: 16px
                width: calc(100% - (12px + 16px))

            .card-info
                > .footnote-4
                    display: -webkit-box
                    -webkit-box-orient: vertical
                    -webkit-line-clamp: 4
                    font-size: 12px
                    line-height: 16px
                    overflow: hidden

            .see-more-button:not(.card-info-icon-wrapper .see-more-button)
                right: 12px

            .tag-wrapper
                max-width: 92px
                    
                &.tag-wrapper-1
                    .card-name
                        margin-left: 0
                        padding: 0
                        background: transparent

                &.tag-wrapper-2
                    position: initial

                    .top-tag
                        padding: 8px 9px
                        background: $color-yellow
                        color: $color-black
                        
                        .title-4
                            font-size: 16px
                            line-height: 28px

                    .big-tag
                        position: absolute
                        right: 12px
                        left: 16px
                        bottom: 48px
                        padding: 0
                        margin-left: 0
                        width: calc(100% - (12px + 16px))
                        background: transparent
                        color: $color-black

                &.tag-wrapper-3, &.tag-wrapper-5
                    .small-tag
                        font-size: 10px
                        line-height: 12px

                &.tag-wrapper-4
                    position: initial
                    
                    .upper-label
                        margin-bottom: 0
                        padding: 8px 10px
                        font-size: 10px
                        line-height: 12px

                    .card-name 
                        position: absolute
                        bottom: 48px
                        padding: 0
                        margin-left: 0
                        background: transparent
                        color: $color-black
                        
                .card-name
                    max-width: 81px
                    font-family: $fontInter
                    font-size: 12px
                    font-weight: 500
                    line-height: 16px
                    text-align: left

            .big-price:not(.old-price .big-price)
                font-size: 30px
                line-height: 38px

            .small-price:not(.old-price .small-price)
                font-size: 24px
                line-height: 30px

            .see-more-button 
                padding: 4px

// BIG Layout
.layout-big-cards
    .catalog-card
        &.small-card, &.medium-card, &.big-card
            grid-column: span 2
            padding: 24px 24px 48px 24px
            height: calc(596px - (24px + 48px))

            .partner-logo
                left: 24px
                width: 122px
                height: 82px
                border-radius: 16px

            .product-img
                top: 135px
                max-height: 266px

            .card-info-icon-wrapper 
                right: 24px
                left: 24px
                width: calc(100% - (24px + 24px))

            .tag-wrapper
                max-width: 140px

                &.tag-wrapper-2
                    position: absolute
                    bottom: 48px
                    left: 24px

                    .big-tag 
                        position: initial
                        margin-left: 10px
                        background: $color-red
                        color: $color-white

                    .small-tag
                        background: $color-yellow
                        color: $color-black

                    .card-name
                        max-width: none
                        font-family: $fontInter
                        font-size: 14px
                        line-height: 18px

                &.tag-wrapper-3, &.tag-wrapper-5
                    .small-tag
                        font-size: 16px
                        line-height: 20px

                &.tag-wrapper-4
                    position: absolute

                    .card-name
                        position: initial
                        padding: 10px 16px
                        margin-left: 10px
                        background: $color-red
                        color: $color-white
                        font-weight: 400
                        font-size: 16px
                        max-width: 140px
                    
                    .upper-label
                        margin-bottom: -30px
                        padding: 8px 10px 40px 10px
                        font-size: 16px                       
                        line-height: 20px

                .big-tag
                    padding: 12px 18px 10px 16px

                .small-tag
                    margin-bottom: -42px
                    padding: 10px 10px 49px 10px

            .big-price:not(.old-price .big-price)
                font-size: 50px
                line-height: 58px

            .small-price:not(.old-price .small-price)
                font-size: 35px
                line-height: 42px

            .card-info
                > .footnote-4 
                    font-size: 24px
                    line-height: 30px

            .see-more-button:not(.card-info-icon-wrapper .see-more-button)
                right: 24px
                    


    
