.shopping-cart-wrapper
    padding: 48px 16px 200px
    min-height: calc(100vh - 196px)

    .content
        display: none

        &.is-active
            display: block

    .info-wrapper 
        margin-bottom: 20px
        padding: 11px 0
        border-top: 1px solid $color-grey-2
        border-bottom: 1px solid $color-grey-2

        div
            display: flex
            width: 100%
            justify-content: space-between
            align-items: center

    .total-wrapper 
        display: flex
        justify-content: space-between
        align-items: center

        div .body-4 
            margin-right: 15px
            color: $color-grey-4
            text-decoration: line-through

        .title-5
            color: $color-red
            font-weight: 700
    
    .btn-red 
        position: fixed
        bottom: 140px
        left: 50%
        transform: translateX(-50%)
        width: calc(100% - 48px)
        max-width: 564px
        transition: bottom .7s
        z-index: 1

        &.going-down 
            bottom: -130px

    .shopping-no-results
        position: absolute
        top: 50%
        left: 50%
        width: 100%
        text-align: center
        transform: translate(-50%, -50%)

        .title-6 
            margin-bottom: 14px

    .picture-cart 
        display: block
        margin-bottom: 24px

    .link-blue 
        color: $color-blue
        text-decoration: underline