.product-detail-wrapper
    position: fixed
    height: 100vh
    z-index: 10000
    bottom: 0
    left: 0
    width: 100vw
    transition: .7s all
    pointer-events: none

    &.show
        pointer-events: auto
        
        .overlay
            opacity: 1

        figure
            bottom: 0

    .overlay
        top: 0
        right: 0
        bottom: 0
        left: 0
        opacity: 0
        transition: .7s all
        width: 100%
        height: 100%
        background-color: rgba($color-black, 0.5)

    figure 
        position: absolute
        right: 0
        bottom: -100vh
        left: 50%
        transform: translateX(-50%)
        padding: 42px 24px 32px
        background-color: $color-white
        border-radius: 20px 20px 0 0 
        transition: bottom 750ms
        box-sizing: border-box
        max-width: 564px
        width: calc( 100% - 48px)
        

    .back-button 
        position: absolute
        top: 20px
        left: 50%
        transform: translateX(-50%) rotate(180deg)
    

    .product-image
        position: relative
        margin-bottom: 24px
        width: 100%
        height: 36vh
        max-height: 231px
        border-radius: 24px
        overflow: hidden
        background-color: $color-lightBlue

        picture 
            display: block
            position: absolute
            top: 50%
            left: 50%
            width: auto
            height: 56%
            transform: translate(-50%, -50%)

        img 
            width: 100%
            height: 100%
            object-fit: cover


    .timer 
        position: absolute
        right: 0
        bottom: 0
        left: 0
        margin: 0 auto
        padding: 8px 26px
        width: 59%
        text-align: center
        background-color: $color-white
        border-radius: 8px 8px 0px 0px

        img 
            margin-right: 6px
            width: 12px
            vertical-align: middle

        .footnote-1 
            color: $color-blue
            text-transform: uppercase

    .discount-label
        position: absolute
        top: 16px
        left: 16px
        padding: 8px 10px
        background: $color-yellow
        border-radius: 12px

    .pvpr
        display: block
        line-height: 1.2
        font-weight: 600
        font-size: 62.5%

    .large-title-2 
        margin-bottom: 9px

    .title-6 
        margin-bottom: 18px
        color: rgba($color-black, 0.5)

    .body-5 
        font-weight: 400

        &:first-child
            position: relative
            padding-right: 20px

            &:after
                content: '•'
                position: absolute
                top: 0
                right: 10px
                width: 4px
                height: 4px
                color: $color-black

    .caption 
        display: flex
        margin-bottom: 27px
        justify-content: space-between
        align-items: center

    .new-price 
        margin-right: 15px
        color: #DB2028
        font-family: $fontNunito
        font-weight: 900
        font-size: 30px
        line-height: 38px

        .small-price 
            font-size: 20px
            line-height: 18px

    .old-price
        // text-decoration: line-through

    .quantity-wrapper 
        padding: 16px
        width: 114px
        border-radius: 28px
        justify-content: space-between

    .share-button 
        margin: 0
        width: 56px
        height: 56px
        background-color: transparent
        border: 2px solid $color-grey-2

        img
            margin: 0 auto

    .buttons-wrapper
        display: flex
        justify-content: space-between
        align-items: center

    
    
