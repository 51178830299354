.video-slider 
    margin-bottom: -100px

    .back-btn 
        position: absolute
        top: 18px
        left: 18px
        z-index: 100
        background: $color-grey-2
        border-radius: 50%
        display: flex

    video 
        width: 100vw
        height: 100vh
        object-fit: cover
        display: block

        @media (min-width: 600px)
            max-width: 600px

            &::after
                max-width: 600px

    .swiper
        width: 100vw
        height: 100vh

        @media (min-width: 600px)
                max-width: 600px

    figure
        position: relative

        &::after
            content: ''
            position: absolute
            left: 0
            right: 0
            bottom: 0
            height: 250px
            background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%)
            mix-blend-mode: normal
            opacity: 0.8
            transform: rotate(-180deg)

            @media (min-width: 600px)
                max-width: 600px

    figcaption
        position: absolute
        left: 30px
        bottom: 133px
        right: 30px
        display: flex
        justify-content: space-between
        align-items: center

        .see-more-btn
            box-shadow: 0px 12px 158px rgba(0, 0, 0, 0.11), 0px 3.92223px 57.6726px rgba(0, 0, 0, 0.075898), 0px 1.93598px 27.999px rgba(0, 0, 0, 0.061192), 0px 0.971662px 13.7256px rgba(0, 0, 0, 0.048808), 0px 0.396426px 5.42713px rgba(0, 0, 0, 0.034102)
            border-radius: 50%
            display: flex
            padding: 8px
            background: $color-white


        p
            color: $color-white

    .video-duration
        color: $color-white
        position: absolute
        top: 28px
        left: 50%
        transform: translateX(-50%)
        z-index: 100
        font-feature-settings: "tnum"
