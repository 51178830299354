.btn 
    padding: 19px 56px  
    border-radius: 40px
    text-transform: uppercase

    &-blue
        background: $color-blue
        color: $color-white

    &-red
        background: $color-red
        color: $color-white

    &-full 
        display: block
        text-align: center