.tab-wrapper
    ul
        display: flex
        position: relative
        padding-bottom: 9px
        margin-bottom: 35px
        margin-left: 23px

        &::after
            content: ''
            position: absolute
            bottom: 0px
            left: -16px
            right: -16px
            height: 1px
            background: #e9e9e9

    button
        transition: .7s all

        &.is-active
            color: $color-blue
            position: relative

            &::after
                content: ''
                position: absolute
                left: 0
                right: 0
                bottom: -9px
                height: 2px
                background: $color-blue


    li
        margin-right: 22px