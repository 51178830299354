.catalog-wrapper
    position: relative
    padding: 110px 18px 100px
    margin-top: -40px
    position: relative
    background: $color-white

    

    .wrapper-border
        position: sticky
        top: 80px
        z-index: 10
        width: calc(100% + 4px)
        margin-left: -2px
    
    header
        position: sticky
        margin: -110px -18px 16px
        padding: 18px 0
        top: 0
        width: 100vw
        background: $color-white
        z-index: 9999
        opacity: 0
        pointer-events: none
        transition: .7s all
        max-width: 582px

        &.showing 
            opacity: 1
            pointer-events: auto
            
        nav ul:not(.tag-wrapper)
            display: flex
            padding-left: 18px
            align-items: flex-start
            max-width: 100vw
            overflow: hidden

            li:last-child
                padding-right: 34px
                overflow: scroll
                -ms-overflow-style: none  /* Internet Explorer 10+ */
                scrollbar-width: none  /* Firefox */
            
                &::-webkit-scrollbar
                    display: none

        li
            position: relative
            white-space: nowrap

            a,button 
                display: flex
            
            &:first-child
                display: flex
                margin-right: 0

            &:last-child
                position: relative

            .search-btn, .layout-change
                position: relative
                background: $color-grey-2
                border-radius: 50%
                padding: 8px
                z-index: 2
            
            .search-btn
                margin-right: 12px

            .filter-tag
                padding: 15px
                border-radius: 36px
                background: $color-grey-2
                color: $color-black
                transition: background-color 250ms ease-in-out, color 250ms ease-in-out

                &.is-active
                    background: $color-blue
                    color: $color-white
                    
        .shape
            content: none
            position: absolute
            top: 0
            right: -29px
            left: auto
            bottom: 0
            width: 30px
            height: 100%
            z-index: 1
            
        .tag-wrapper
            display: flex

            li:first-child
                margin-left: 12px

            li:not(first-child)
                margin-right: 12px
                overflow: initial !important

    .collection
        .collection-wrapper
            display: grid
            grid-template-columns: 1fr 1fr
            grid-gap: 16px
        h2 
            margin-bottom: 23px
