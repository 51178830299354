body
    position: relative
    font-size: 16px
    font-family: $fontNunito    
    padding-bottom: 20px
    transition: .8s all
    max-width: 600px
    margin: 0 auto
    position: relative

    &.no-scroll
        overflow: hidden

html
    scroll-behavior: smooth
    
li 
    list-style: none

.main-logo
    position: absolute
    top: 31px
    left: 50%
    z-index: 9999
    transform: translateX(-50%)

.js-intersectionLoadItems 
    position: absolute
    right: 0
    bottom: 100px
    left: 0
    width: 100%
    height: 2px
    pointer-events: none

a
    color: unset

.is-hidden 
    opacity: 0


.fade-enter 
    opacity: 0
    z-index: 1

.fade-enter.fade-enter-active 
    opacity: 1
    transition: opacity 750ms ease-out, transform 300ms ease

.fade-exit 
    opacity: 1

.fade-exit.fade-exit-active 
    opacity: 0
    transition: opacity 750ms ease-out, transform 300ms ease
