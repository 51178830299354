.main-nav
    position: fixed
    bottom: 32px
    left: 50%
    transform: translateX(-50%)
    width: calc(100% - 48px)
    background: $color-blue
    z-index: 999999
    padding: 17px 23px
    border-radius: 40px
    box-shadow: 0 8px 20px 0 rgba($color-black,.55)
    transition: .7s all
    box-sizing: border-box
    opacity: 1
    max-width: 562px

    &.going-down
        bottom: -100px
        opacity: 0

    ul 
        display: flex
        width: 100%
        justify-content: space-between
        align-content: center

        li a
            display: flex
            text-align: center
            flex-direction: column
            align-items: center
            position: relative

            &::before
                content: ''
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%,-50%)
                width: 0
                height: 0
                transition: .7s all
                background: $color-grey
                opacity: .2
                border-radius: 50%

            &.is-active::before
                width: 70px
                height: 70px

            img
                width: 26px
                height: 26px

            span
                font-size: 10px
                line-height: 16px
                font-weight: 600
                font-family: $fontInter
                color: $color-white 

            .cart-count
                font-size: 10px
                line-height: 4px
                color: $color-black
                background: $color-white
                padding: 6px 5px
                border-radius: 50%
                position: absolute
                top: -7px
                right: -7px