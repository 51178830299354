$color-white: #FFFFFF
$color-black: #242312
$color-blue: #004A93
$color-darkBlue: #003770
$color-lightBlue: #E6ECF8
$color-red: #E40520
$color-yellow: #FFF104
$color-grey: #D8D8D8
$color-grey-2: #DFDFDF
$color-grey-3: #F2F2F2
$color-grey-4: #B0B0B0